<template>
  <div
    class="container-md col-lg-11 col-md-11 contact-user-list mt-3 mb-4 pb-3"
  >
    <LoadingOverlay v-if="isCustomerFetch" />
    <div v-else>
      <h4 class="text-primary pt-1 pb-2">
        User List
        <span class="nowrap" style="font-size: 20px"
          >({{ contactUsers.length || "0" }} Record{{
            contactUsers.length > 1 ? "s" : ""
          }})</span
        >
      </h4>
      <CRow>
        <CCol md="6" class="mb-3">
          <CRow>
            <label class="col-lg-12 col-md-12">Organisation</label>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <Select
                name="organisation_id"
                :value="user.organisation_id"
                :options="
                  options && options['organisations']
                    ? options['organisations']
                    : []
                "
                :multiple="false"
                :clearable="false"
                @input="handleChangeSelect"
              />
            </div>
          </CRow>
        </CCol>
      </CRow>
      <CTabs variant="tabs" :active-tab="0">
        <CTab :title="'Users' + ` (${users.length}) `">
          <CDataTable
            :striped="striped"
            :bordered="bordered"
            :small="small"
            :items="users"
            :fields="fields"
            :items-per-page="small ? limit : 5"
            sorter
            :tableFilter="{ label: 'Search', placeholder: 'Type for search' }"
            pagination
            class="p-2 border"
          >
            <template #action="{ item, index }">
              <td class="py-2">
                <CButton
                  color="primary"
                  square
                  size="sm"
                  class="mr-1"
                  @click="editUser(item, index)"
                  v-c-tooltip="'Edit'"
                  ><i class="far fa-edit"></i
                ></CButton>
              </td>
            </template>
          </CDataTable>
        </CTab>
        <CTab :title="'Contacts' + ` (${contacts.length}) `">
          <CDataTable
            :striped="striped"
            :bordered="bordered"
            :small="small"
            :items="contacts"
            :fields="fields"
            :items-per-page="small ? limit : 5"
            sorter
            :tableFilter="{ label: 'Search', placeholder: 'Type for search' }"
            pagination
            class="p-2 border"
          >
            <template #action="{ item, index }">
              <td class="py-2">
                <CButton
                  color="primary"
                  square
                  size="sm"
                  class="mr-1"
                  @click="editUser(item, index)"
                  v-c-tooltip="'Edit'"
                  ><i class="far fa-edit"></i
                ></CButton>
              </td>
            </template>
          </CDataTable>
        </CTab>
      </CTabs>
      <EditContactUser
        v-if="contactUserEditModal.isShowPopup"
        :isShowPopup="contactUserEditModal.isShowPopup"
        :userDetails="contactUserEditModal.userDetails"
        :contactID="contactUserEditModal.contactID"
        @modalCallBack="contactUserEditModalCallBack"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import EditContactUser from "@/containers/ContactUser/EditContactUser";
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select.vue";
export default {
  components: {
    EditContactUser,
    Select,
  },
  data() {
    return {
      user: {},
      limit: 5,
      small: true,
      striped: true,
      bordered: true,
      sorter: { external: true, resetable: true },
      fields: [
        { key: "name", _style: "width:20%" },
        {
          key: "customer_user_type",
          label: "Role",
          _style: "width:20%",
        },
        {
          key: "organisation",
          _style: "width:20%",
        },
        {
          key: "department",
          _style: "width:20%",
        },
        {
          key: "contact_type",
          label: "Job Title / Level",
          _style: "width:17%",
        },
        { key: "email", _style: "width:15%" },
        { key: "action", sorter: false, filter: false, _style: "width:7%" },
      ],
      contactUserEditModal: {
        isShowPopup: false,
        userDetails: null,
        contactID: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "contactUsers",
      "isCustomerFetch",
      "getUser",
      "organizationId",
      "getOrgIDFromAccessToken",
      "getCustomerId",
      "getIsChildUser",
    ]),
    users() {
      return (
        (this.contactUsers &&
          this.contactUsers.length &&
          this.contactUsers
            .filter((v) => v?.user && v?.user_id)
            .map((val) => ({
              name: val?.name || "--",
              email: val?.email || "--",
              customer_user_type:
                (val?.user?.users_roles && val?.user?.users_roles.length) ||
                (val?.user?.recruiters_roles &&
                  val?.user?.recruiters_roles.length)
                  ? val?.user?.users_roles
                      .map(({ display_name }) => display_name)
                      .concat(
                        val?.user?.recruiters_roles &&
                          val?.user?.recruiters_roles.length
                          ? ["Customer Recruiter"]
                          : []
                      )
                      .join(", ")
                  : "--",
              customer_recruiter:
                val?.user?.recruiters_roles &&
                val?.user?.recruiters_roles.length,
              contact_type: val?.contact_type?.contact_type || "--",
              contact_id: val?.contact_id,
              userData: val,
              isContact: !isObject(val?.user) || isEmptyObjectCheck(val?.user),
              department: val?.department?.department_name || "--",
              organisation: val?.contact_user_detail?.organisation_name,
              organisation_id: val?.contact_user_detail?.organisation_id,
            }))
            .reverse()) ||
        []
      );
    },
    contacts() {
      return (
        (this.contactUsers &&
          this.contactUsers.length &&
          this.contactUsers
            .filter((v) => !v?.user && !v.user_id)
            .map((val) => ({
              name: val?.name || "--",
              email: val?.email || "--",
              customer_user_type:
                (val?.user?.users_roles && val?.user?.users_roles.length) ||
                (val?.user?.recruiters_roles &&
                  val?.user?.recruiters_roles.length)
                  ? val?.user?.users_roles
                      .map(({ display_name }) => display_name)
                      .concat(
                        val?.user?.recruiters_roles &&
                          val?.user?.recruiters_roles.length
                          ? ["Customer Recruiter"]
                          : []
                      )
                      .join()
                  : "--",
              customer_recruiter:
                val?.user?.recruiters_roles &&
                val?.user?.recruiters_roles.length,
              contact_type: val?.contact_type?.contact_type || "--",
              contact_id: val?.contact_id,
              userData: val,
              isContact: !isObject(val?.user) || isEmptyObjectCheck(val?.user),
              department: val?.department?.department_name || "--",
              organisation: val?.contact_user_detail?.organisation_name || '--',
              organisation_id: val?.contact_user_detail?.organisation_id,
            }))
            .reverse()) ||
        []
      );
    },
    options() {
      return {
        organisations: this.organizationId || [],
      };
    },
  },
  methods: {
    ...mapActions([
      "fetchCustomerContactUser",
      "fetchUser",
      "updateCustomerContactUser",
      "fetchOrganizationId",
    ]),
    editUser(item, index) {
      this.tooltipHide();
      this.contactUserEditModal.isShowPopup = true;
      this.contactUserEditModal.userDetails = item?.userData;
      this.contactUserEditModal.contactID = item?.userData?.contact_id;
      this.contactUserEditModal.organisation_id = item?.organisation_id;
    },
    contactUserEditModalCallBack(action, payload) {
      if (action === false) {
        this.contactUserEditModal.isShowPopup = false;
      } else if (action === true && payload) {
        const { organisation_id } = this.contactUserEditModal;
        this.updateCustomerContactUser({ payload, organisation_id }).then(
          (res) => {
            this.contactUserEditModal.isShowPopup = false;
            this.fetchCustomerContactUser({
              organisation_id: this.user?.organisation_id?.code,
            });
          }
        );
      }
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 50);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.user, name, value);
      if (name === "organisation_id") {
        this.fetchCustomerContactUser({
          organisation_id: value?.code,
        });
      }
    },
  },
  mounted() {
    let appendAction = [];
    if (!(isObject(this.getUser) && !isEmptyObjectCheck(this.getUser)))
      appendAction = [...appendAction, this.fetchUser()];
    Promise.all(appendAction).then(async (res) => {
      let payload = { customer_uid: this.getCustomerId };
      if (this.getIsChildUser && this.getOrgIDFromAccessToken)
        payload = {
          ...payload,
          organisation_id: this.getOrgIDFromAccessToken,
        };
      await this.fetchOrganizationId(payload);
      const organisation = this.organizationId.filter(
        ({ code }) => code === this.getOrgIDFromAccessToken
      );
      if (organisation.length) {
        this.handleChangeSelect("organisation_id", organisation[0]);
      }
    });
  },
};
</script>
